@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i");

body {
    color: white;
    font-family: Rubik, sans-serif;
    animation: fade 0.25s;
    background: #232425;
}

@keyframes fade {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.Title {
    color: white
}

.bingo-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px; /* Add padding for spacing */
}

.bingo-item {
    border: 1px solid #555; /* Darker border color */
    padding: 10px;
    text-align: center;
    background-color: #37474F; /* Slightly lighter background color */
    border-radius: 5%;
}

a {
    outline: 0;
}
