.bingo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.questTitle {
    text-decoration: none;
    color: white;
}

.bingo-submission-taken {
    background: #1b2d22;
}

.bingo-submission-pending {
    background: #082c49;
}

.bingo-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 20vw;
}

a {
    text-decoration: none;
    outline: 0;
    color: white
}

a:visited {
    color: white
}

a:active {
    color: white
}
#required{
    border-color: gold;
    border-width: 2px;
}
