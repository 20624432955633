.admin-list-container, .admin-quest-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.admin-user-item {
    background: #082c49;
    border-radius: 2px;
    border: 1px solid #424242;
    margin: 1em;
    padding: 1em;
}

.quest-item {
    padding: 1em;
    margin: 1em;
    border-radius: 2px;
    border: 1px solid #424242;
}

.quest-pending {
    background: #082c49;
}

.quest-green {
    background: #1b2d22;
}