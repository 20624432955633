.questTitle {
    color: white;
    display: flex;
    justify-content: center;
}

.questDescription {
    color: rgb(125, 130, 199);
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.quizTextarea {
    background: #424242;
    color: white;
    border-radius: 4px;
    border: 1px solid #424242;
    font-family: Rubik, sans-serif;
    width: 32em;
    max-width: 90%;
    height: 16em;
    padding: 1em;
}

input[type=number], input[type=text] {
    background: #424242;
    color: white;
    border-radius: 4px;
    border: 1px solid #424242;
    font-family: Rubik, sans-serif;
    width: 24em;
    max-width: 90%;
    padding: 1em;
}

.quizTextarea:disabled {
    color: #989898;
}

.quiz-container {
    width: 100%;
}

.submit-container {
    width: 100%;
}