.admin-dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.admin-item {
    border: 1px solid #555; /* Darker border color */
    padding: 1em;
    text-align: center;
    background-color: #373a3c; /* Slightly lighter background color */
    border-radius: 2px;
    margin: 1em;
}

.red-admin-item {
    background: #b16b71;
    border: 1px solid #ff8585;
}

.admin-item h3 {
    margin: 0 0 5px;
    color: #fff; /* White text color */
}

.admin-item p {
    margin: 0;
    color: #ccc; /* Lighter text color */
}

.admin-item a {
    color: #6cb2eb; /* Lighter blue link color */
    text-decoration: none;
}

.admin-item a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
}