.App {
    text-align: center;
}

.good-infobox {
    background: #1b2d22;
    border: 1px solid #424242;
    padding: 1em;
    border-radius: 2px;
}

.bad-infobox {
    background: #3b1818;
    border: 1px solid #424242;
    padding: 1em;
    border-radius: 2px;
}

.pending-infobox {
    background: #082c49;
    border: 1px solid #424242;
    padding: 1em;
    border-radius: 2px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

button {
    background-color: #0D47A1;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.5em;
    box-shadow: 0 0 4px 2px rgba(59, 59, 59, 0.75);
}

.googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: #808080;
    border-radius: 2px;
    font-weight: bold;
    margin: auto;
}

.googleButton:hover {
    background: #e0e0e0;
}

.googleButton img {
    margin-right: 8px;
}

.login-required {
    text-align: center;
}