input::placeholder {
    color: #ccc;
}

input:focus {
    outline: none;
    border-color: #6cb2eb;
}

button:hover {
    background-color: #4a8fc9;
}

.fake-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fake-image {
    width: auto;
    max-width: 90vw;
    height: 450px;
    max-height: 50vh;
    margin: 0 auto;
}

.quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.quiz-button {
    color: white;
    font-size: 20px;
}

@media (max-width: 699px) {
    .quiz-button {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .quiz-button-row {
        display: block;
        width: 100%;
    }

    .answer-container {
        width: 90%;
        margin: auto;
    }
}

@media (min-width: 700px) {
    .quiz-button-row {
        max-width: 700px;
        width: 100%;
        display: flex;
    }

    .quiz-button {
        font-size: 20px;
        flex: 1 1 auto;
        margin: 1em 1em 0 0;
        width: 50%;
    }

    .quiz-button:nth-child(2) {
        margin: 1em 0 0 0;
    }

    .quiz-button-row:nth-child(1) .quiz-button {
        margin-top: 0;
    }

    .quiz-button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .answer-container {
        max-width: 700px;
        margin: auto;
    }
}

.dropzone {
    font-family: monospace;
    font-weight: bold;
    font-size: 16pt;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 4px dashed #4d4d4d;
    background: #333333;
    min-height: 100px;
    max-width: 450px;
    width: 90vw;
}

.dropzone img {
    max-width: 90vw;
    max-height: 200px;
}